module.exports = [{
      plugin: require('../node_modules/gatsby-wordpress-inline-images/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.swiatubezpieczen.com/"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Bezpłatna porównywarka ubezpieczeń - swiatubezpieczen.com","short_name":"Swiatubezpieczen.com","description":"Witaj w Świecie Ubezpieczeń! To nie tylko nowoczesna i wygodna porównywarka polis, ale też źródło najbardziej rzetelnej wiedzy na temat ubezpieczeń. Znajdź polisę, dopasowaną do swoich potrzeb i zapewnij sobie najlepszą ochronę.","lang":"pl","display":"standalone","start_url":"/","background_color":"#244799","theme_color":"#fff","icon":"src/img/favicon.png","globPatterns":["**/*.{js,jpg,png,html,css}"],"importWorkboxFrom":"local","dontCacheBustURLsMatching":{},"runtimeCaching":[{"urlPattern":{},"handler":"CacheFirst"},{"urlPattern":{},"handler":"NetworkFirst"},{"urlPattern":{},"handler":"StaleWhileRevalidate"},{"urlPattern":{},"handler":"StaleWhileRevalidate"}],"skipWaiting":true,"clientsClaim":true,"cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"5557fed9c2718a3dbcf39345544eaf4a"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-5DDJSNB","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
